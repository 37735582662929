<template>
  <div class="zhihuishuiwu">
    <div class="biaoti">
      <span class="el-icon-caret-bottom"></span>
      方案介绍
    </div>
    <div class="text">
      汉康智慧水务解决方案是利用物联网、云计算、大数据、区块链、AI、GIS+BIM等新一代信息技术，打造透彻感知、全面互联、深度挖掘、智能应用、综合决策、泛在服务的水务管理新模式，全面提升水资源利用、水工程运行、水安全保障、水灾害防御、水环境保护、水生态治理、水行政监管、水事务管理八大业务范畴的综合决策智能化、智慧化能力。
    </div>

    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      产品概述
    </div>
    <div class="text">
      I-Ching包含：“设备接入管理”、“设备安全认证管理”、“设备监测与维护管理”、“边缘计算管理”、“物联网数据集成管理”、“分析组合
      管理”、“一张图网络管理”、“总线运营管理”等几大功能/应用。物联网总线平台，主要应用赋能基于各市级、县级政府和基于购买服务模式
      的“政务、水务、消防、安防、交通”等B端G端用户。帮助政府和企业构建一体化的物联、数据、应用集成管理大平台。
    </div>
    <img class="img" src="../../../../assets/imgs/fangan/gaishui.png" alt="" />
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      方案主要构成模块介绍
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">两大保障体系</div>
      <div class="li-text">
        信息安全体系和标准规范体系是智慧水务规范、有序、健康发展的重要保证，是实现数据共享、应用升级的前提，以信息安全为保障、标准规范为核心，指导、约束智慧水务建设全生命周期的规划、设计、建设和运营的各个阶段。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">水务感知网络</div>
      <div class="li-text">
        通过水务传感器、视频监控、无人机、卫星遥感等方式形成空天地立体水务感知体系，实现智慧水务透彻感知。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">物联网总线平台</div>
      <div class="li-text">
        为智慧水务提供感知层的设备接入、边缘计算、设备管理、数据分析、设备认证、网络管理和安全运营等服务，主要应用于“政务、水务、消防、安防、交通”等B端和G端用户，帮助政府和企业建立一个安全可靠的万物互联世界。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据层</div>
      <div class="li-text">
        构建智慧水务数据仓库、算法模型，并通过机器学习和深度学习为智慧水务智能应用和综合决策提供科学依据。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">区块链</div>
      <div class="li-text">
        打造智慧水务区块链和智能合约的开放服务平台，推动建立智慧水务区块链应用生态圈和智能合约开发社区。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数字底板</div>
      <div class="li-text">
        基于GIS、BIM、CIM平台，为物理真实水务提供数字孪生的可视化、运营管理、物联控制、仿真预测的平台级服务。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据服务平台</div>
      <div class="li-text">
        为智慧水务提供数据标准、数据开发、数据管理、数据分析、数据共享和可编API服务，实现水务大数据的开放、共享和价值创造。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">业务服务平台</div>
      <div class="li-text">
        为智慧水务业务应用提供统一服务支撑、应用支撑和共性支撑，实现各业务板块之间链接和协同，持续提升业务创新效率，确保关键业务链路的稳定高效和经济性。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智能应用平台</div>
      <div class="li-text">
        提供涵盖水资源、水工程、水安全、水灾害、水环境、水生态、水行政、水事务八大业务范畴十三类业务应用的全流程解决方案，实现水务业务流程优化再造。
      </div>
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      方案优势
    </div>
    <div class="bar mt20">
      <div class="em"></div>
      <div class="top">感知能力</div>
      <div class="bottom">空天地立体感知体系</div>
    </div>
    <div class="bar mt10">
      <div class="em"></div>
      <div class="top">透析能力</div>
      <div class="bottom">基于自主研发的GIS+BIM引擎，构建数据孪生智慧水务</div>
    </div>
    <div class="bar mt10">
      <div class="em"></div>
      <div class="top">数据能力</div>
      <div class="bottom">全业务流程的数据治理和模型构建能力</div>
    </div>
    <div class="bar mt10">
      <div class="em"></div>
      <div class="top">安全能力</div>
      <div class="bottom">利用区块链技术将关键数据上链实现数据安全可靠</div>
    </div>
    <div class="bar mt10">
      <div class="em"></div>
      <div class="top">业务能力</div>
      <div class="bottom">支撑水务八大业务范畴的流程优化再造</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.zhihuishuiwu {
  width: 870px;
  padding-bottom: 60px;
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .img {
    width: 870px;
    height: 716px;
    margin-top: 30px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
  .bar {
    width: 100%;
    height: 94px;
    background-color: rgba(68, 112, 255, 0.05);
    padding: 20px 40px;
    position: relative;
    border: 1px solid rgba(148, 173, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .em {
      position: absolute;
      top: 24px;
      left: 16px;
      width: 10px;
      height: 10px;
      background: rgba(255, 125, 68, 1);
      border-radius: 50%;
    }
    .top {
      display: flex;
      align-content: inherit;
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;

      color: #333333;
    }
    .bottom {
      display: flex;
      align-content: inherit;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;

      color: #333333;
    }
  }
}
</style>